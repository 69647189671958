import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

export default  new Router({
  // mode: "history",
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/',
      component: () => import('@/components/common/Base.vue'),
      meta: {
        title: '公共部分'
      },
      children: [
        {
          path: '/Home',
          component: () => import('@/views/Home.vue'),
          meta: {
            title: '总览'
          }
        },
        // {
        //   path: '/EchartsSimple',
        //   component: () => import('@/views/EchartsSimple.vue'),
        //   meta: {
        //     title: '简单图表'
        //   }
        // },
        // {
        //   path: '/EchartsComplex',
        //   component: () => import('@/views/EchartsComplex.vue'),
        //   meta: {
        //     title: '复杂图表'
        //   }
        // },
        {
          path: '/SubjectManagement',
          component: () => import('@/views/SubjectManagement.vue'),
          meta: {
            title: '学科管理'
          }
        },
        {
          path: '/CourseManagement',
          component: () => import('@/views/CourseManagement.vue'),
          meta: {
            title: '视频管理'
          }
        },
        // {
        //   path: '/TextbookManagementSuperior',
        //   component: () => import('@/views/TextbookManagementSuperior.vue'),
        //   meta: {
        //     title: '教材管理上册'
        //   }
        // },
        {
          path: '/TextbookManagement',
          component: () => import('@/views/TextbookManagement.vue'),
          meta: {
            title: '教材管理'
          }
        },
        {
          path: '/mistakenBookManagement',
          component: () => import('@/views/mistakenbookManagement.vue'),
          meta: {
            title: '错题本管理'
          }
        },   
        {
          path: '/ExaminationPaperManagement',
          component: () => import('@/views/ExaminationPaperManagement.vue'),
          meta: {
            title: '组卷管理'
          }
        },
        {
          path: '/MaterialManagement',
          component: () => import('@/views/MaterialManagement.vue'),
          meta: {
            title: '学习资料管理'
          }
        },  
        {
          path: '/UserManagement', 
          component: () => import('@/views/UserManagement.vue'),
          meta: {
            title: '用户管理'
          }
        }
        // {
        //   path: '/table',
        //   component: () => import('@/views/Table.vue'),
        //   meta: {
        //     title: '表格'
        //   }
        // }
      ]
    },
    {
      path: '/login',
      component: () => import('@/views/Login.vue')
    },
    {
      path: '/error',
      component: () => import('@/views/Error.vue')
    },
    {
      path: '/404',
      component: () => import('@/views/404.vue')
    }
  ]
})

