import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 当前登录者邮箱账号
    loginAccount:'',
    // 当前登录者姓名
    loginName:'',
    // 当前登录者信息
    loginList:[]
  },
  getters: {
    getLoginAccount(state){
      return state.loginAccount
    },
    getLoginList(state){
      return state.loginList
    },
    getLoginName(state){
      return state.loginName
    }
  }, 
  mutations: {
    setLoginAccount(state,string){
      state.loginAccount = string
    },
    setLoginList(state,object){
      state.loginList = object
      console.log('存储了当前登录用户信息',object)
    },
    setLoginName(state,string){
      state.loginName = string
    }
  },
  actions: {
    changeLoginAccount(context, string) {
      context.commit('setLoginAccount', string)
    },
    changeLoginList(context, object) {
      context.commit('setLoginList', object)
    },
    changeLoginList(context, string) {
      context.commit('setLoginName', string)
    }
  },
  modules: {
  }
})
