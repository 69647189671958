import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ElementUI from 'element-ui';
import '@/assets/css/common.less';
import '@/assets/css/variable.less';
import '@/assets/css/theme/459f75/index.css';
// import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI, {size: 'medium'});
Vue.use(VueAxios,axios)
Vue.config.productionTip = false
Date.prototype.Format = function (fmt, hasweek) {

  var weekday = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
  var o = {
    "M+": this.getMonth() + 1,
    "d+": this.getDate(),
    "h+": this.getHours(),
    "m+": this.getMinutes(),
    "s+": this.getSeconds(),
    "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
    "S": this.getMilliseconds() // 毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));

  return fmt + (hasweek ? "&nbsp;&nbsp;&nbsp;&nbsp;" + weekday[this.getDay()] : "");
};

// 全局路由守卫
router.beforeEach((to, from, next) => {
  if(to.path==='/'||to.path==='/login'){
    next()
  }else{
    if(window.localStorage.getItem('token')){
      next()
    }else{
      next({path:'/login'})
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
